import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Field } from '../new-field';
import { InputFieldParams } from './input-field';
import { useSiteConfigContext } from 'style-context';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { getFieldError, getNextComponentId, prefillBehavior, setFormFocusOrBlur } from './utils';
import { getSteppedFormLabelColor, onEnterKeyDown, SteppedFormButton } from './utils/stepped-form';
import { Colors } from 'rootstrap/global-styles/colors';
import { StyledInput, StyledReactNumberComponent } from './styled-input';
import styled from 'styled-components';
import { globalStyles } from 'rootstrap/global-styles';
import { StyledRootSchemaComponent } from './styles/root-schema-component-style';
import { getValidationMessage, ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { formatToCurrency, getCurrencySymbol } from './utils/currency-format';
import { Currency } from 'product-modules/domain/product-module-definition-settings';

export const CurrencyField = ({
  asCents = true,
  label,
  name,
  helpText,
  defaultValue,
  isDisabled,
  form,
  prefillAction,
  prefillValue,
  disableActiveElement,
  displayProperties,
  disableScrollToElement,
  disableNextButton,
  validators,
  hideDivider,
  disableTitle,
  isTouched: defaultIsTouched,
  currency,
  submitOnChange,
  hideBorder,
  hiddenComponent,
}: InputFieldParams<number> & { asCents?: boolean; currency: Currency | undefined }) => {
  const { siteConfig } = useSiteConfigContext();
  const [isTouched, setTouched] = useState<boolean>(!!prefillValue || defaultIsTouched);
  const [fieldError, setFieldError] = useState<string | undefined>(
    getFieldError({ errors: form?.errors, isTouched, name }),
  );
  const { activeElement, setActiveElement } = displayProperties;
  const [isRequired] = useState<boolean>(
    !!validators?.find(({ validation }) => validation.type === ValidationTypes.REQUIRED),
  );
  const isActive = activeElement.elementId === name;
  const currencySymbol = getCurrencySymbol({ currencyCode: currency });

  const { disabledFromPrefill, hiddenFromPrefill } = prefillBehavior({
    prefillAction,
    prefillValue,
    options: undefined,

    validate: (value) =>
      getValidationMessage({
        validators: validators,
        value,
        props: {
          prefix: currencySymbol,
        },
      }),
  });

  useEffect(() => {
    if (prefillValue) {
      setFieldError(
        getValidationMessage({
          validators: validators,
          value: form?.watch(name),
          props: undefined,
        }),
      );
    }
  }, []);

  const errors = form?.errors;

  useEffect(() => {
    if (isActive && hiddenFromPrefill.display && setActiveElement && !isTouched) {
      setActiveElement({ elementId: getNextComponentId({ ...displayProperties, key: name }) });
    }
  }, [hiddenFromPrefill.display, isActive]);

  useEffect(() => {
    setFormFocusOrBlur({
      activeElement,
      scrollToId: name,
      disableScrollToElement,
      isFirstElement: displayProperties.index === 0,
      nextButtonFocus: false,
    });
  }, [JSON.stringify(errors), isTouched, name, isActive]);

  return (
    <div id={name ? `${name}-form-group` : undefined} style={hiddenFromPrefill}>
      <StyledRootSchemaComponent
        className='schema-component'
        isActive={isActive}
        disableActiveElement={disableActiveElement}
        isFirstElement={displayProperties.index === 0}
        hiddenComponent={hiddenComponent}
      >
        <Field
          disableTitle={disableTitle}
          hiddenComponent={hiddenComponent}
          isActive={isActive}
          onClick={() => setActiveElement({ elementId: name })}
          name={name}
          label={label}
          errors={fieldError ? [fieldError] : []}
          isRequired={isRequired}
          helpText={helpText}
          style={{
            ...hiddenFromPrefill,
            color: getSteppedFormLabelColor({ isActive, siteConfig, color: Colors.Body }),
          }}
          isTouched={isTouched}
        >
          <Controller
            name={name}
            control={form?.control}
            defaultValue={defaultValue}
            rules={{
              required: isRequired ? 'Required' : undefined,
              validate: (value) =>
                getValidationMessage({
                  validators: validators,
                  value,
                  props: {
                    prefix: getCurrencySymbol({ currencyCode: currency }),
                  },
                }),
            }}
            render={({ onChange, value }) => {
              return (
                <>
                  <StyledPrefixField>
                    {currencySymbol && (
                      <StyledPrefixContainer siteConfig={siteConfig} isActive={isActive} isTouched={isTouched}>
                        <span>{currencySymbol}</span>
                      </StyledPrefixContainer>
                    )}
                    <StyledReactNumberComponent
                      isTouched={isTouched}
                      siteConfig={siteConfig}
                      isActive={isActive}
                      disableActiveElement={disableActiveElement}
                      name={name}
                      id={name}
                      disabled={isDisabled || disabledFromPrefill}
                      step='any'
                      onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                        // @ts-ignore
                        e.target.blur();
                      }}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        onEnterKeyDown({
                          e,
                          nextComponentId: getNextComponentId({ ...displayProperties, key: name }),
                          setFieldError: (fieldError) => setFieldError(fieldError),
                          setActiveElement: (elementId) =>
                            setActiveElement({
                              elementId,
                            }),
                          getValue: () => form?.watch(name),
                          validationFunction: getValidationMessage,
                          validators,
                          validationProps: {
                            prefix: currencySymbol,
                          },
                          setIsTouched: (isTouched) => setTouched(isTouched),
                        })
                      }
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        setTouched(true);
                        e.preventDefault();
                      }}
                      onValueChange={({ value }) => {
                        const result =
                          value === ''
                            ? value
                            : Number.parseInt((Number.parseFloat(value) * (asCents ? 100 : 1)).toFixed(0), 10);
                        onChange(result === '' ? undefined : result);
                        return setFieldError(
                          getValidationMessage({
                            validators: validators,
                            value: result === '' ? undefined : result,
                            props: {
                              prefix: currencySymbol,
                            },
                          }),
                        );
                      }}
                      onClick={() =>
                        setFormFocusOrBlur({
                          activeElement,
                          scrollToId: name,
                          isFirstElement: displayProperties.index === 0,
                        })
                      }
                      onChange={(a) => {
                        //   e.preventDefault();
                        //   e.stopPropagation();
                      }}
                      {...({} as any)}
                      format={(value: string) =>
                        formatToCurrency({
                          number: value,
                          currencyOptions: {
                            currencyCode: currency,
                            excludeSymbol: true,
                            excludeCents: true,
                          },
                        })
                      }
                      value={value === '' ? value : value / (asCents ? 100 : 1)}
                      invalid={!!fieldError}
                    />
                  </StyledPrefixField>
                </>
              );
            }}
          />
        </Field>
        {!hiddenComponent && (
          <SteppedFormButton
            disableActiveElement={!!disableActiveElement}
            hideBorder={hideBorder}
            submitOnChange={submitOnChange}
            setTouched={(isTouched) => setTouched(isTouched)}
            hideDivider={hideDivider}
            nextComponentId={getNextComponentId({ ...displayProperties, key: name })}
            getValue={() => form?.watch(name)}
            parentName={name}
            isDisabled={!!fieldError}
            isActive={isActive}
            setFieldError={(fieldError) => setFieldError(fieldError)}
            setActiveElement={(elementId) =>
              setActiveElement({
                elementId,
              })
            }
            disableNextButton={disableNextButton}
            validationFunction={getValidationMessage}
            validators={validators}
            validationProps={{
              prefix: currencySymbol,
            }}
          />
        )}
      </StyledRootSchemaComponent>
    </div>
  );
};

export const StyledPrefixField = styled.div`
  display: flex;
  width: 100%;

  div {
    display: flex;
  }
`;

export const StyledPrefixedInput = styled(StyledInput)<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
}>`
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
`;

export const StyledPrefixContainer = styled.span<{
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null;
  isActive: boolean;
  isTouched: boolean;
}>`
  min-width: 64px;
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  color: ${globalStyles.colors.Body};
  border: 1px solid
    ${({ siteConfig, isActive, isTouched }) =>
      isTouched || isActive ? globalStyles.colors.Body : getColor({ color: 'disabled', siteConfig })};
  border-top-left-radius: ${globalStyles.borderRadius.default};
  border-bottom-left-radius: ${globalStyles.borderRadius.default};
  border-right: none;

  span {
    color: ${({ isActive, siteConfig, isTouched }) =>
      getSteppedFormLabelColor({ isActive: isActive || isTouched, siteConfig, color: Colors.Body })};
  }
`;
