import { Application } from 'applications/domain/application';
import { PaymentMethodType } from 'payment-methods/domain/payment-method';
import { Policyholder } from 'policyholder/domain/policyholder';
import { ProductModule } from 'product-modules/domain/product-module';
import { ProductModuleDefinitionSchema } from 'product-modules/domain/product-module-definition-schema';
import React, { useEffect, useMemo, useState } from 'react';
import { ActiveElement, setFormFocusOrBlur } from 'rootstrap/components/forms/new-fields/utils';
import { SteppedFullScreenModalComponentParams } from 'rootstrap/components/modal/stepped-fullscreen-modal';
import { globalStyles } from 'rootstrap/global-styles';
import { getPremiumDisplay } from 'shared/utils';
import { getColor, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import styled from 'styled-components';
import { CreditCardPaymentMethod } from './views/credit-card';
import { DebitOrderPaymentMethod } from './views/debit-order-payment';
import { ExternalPaymentMethod } from './views/external-payment';
import { PaymentDeclarationSection } from './views/payment-declaration-section';
import { SkippedPaymentMethod } from './views/skipped-payment-method';
import _ from 'lodash';
import { CreateCollectionModulePaymentMethod } from './views/collections-module-payment-method';
import { usePromise } from 'shared/hooks/promise';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { getProductModuleCollectionModules } from 'collection-modules/actions/get-product-module-collection-modules';
import { SelectField } from 'rootstrap/components/forms/new-fields/select-field';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { useForm } from 'react-hook-form';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { Col, Row } from 'reactstrap';
import { getNewPaymentMethodOptions } from './utils/get-new-payment-method-options';
import { StyledTitle } from 'policy-issuing/common/styled-title';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { MixpanelStepNames, useMixpanelTrack } from 'context/mix-pannel-context';
import { Environment } from 'models/environment';
import { CollectionModule, CollectionModuleDefinitionVersion } from 'collection-modules/domain/collection-module';
import { useQueryParams } from 'shared/hooks/url';
import { getCollectionModuleVersionFromQueryParam } from './utils/get-collection-module-version-from-query-param';
import { DebitOrderPaymentInputs } from 'policy-management/payment-details/forms/debit-order-payment-method-form';
import { LegacyPaymentSummary } from './views/legacy-payment-summary';
import CombinedPaymentSummary from './views/payment-summary';

export interface BasePaymentMethodProps extends SteppedFullScreenModalComponentParams {
  productModule?: ProductModule;
  productModuleDefinitionQuoteSchema?: ProductModuleDefinitionSchema;
  setAccountPermission: (v: boolean) => void;
  debitAccountPermission: boolean;
  application?: Application;
  policyholder?: Policyholder;
  setPaymentMethodIsValid: (isValid: boolean) => void;
  collectionModuleKey?: string;
}

export interface PaymentMethodProps extends BasePaymentMethodProps {
  setActiveElement: (params: ActiveElement) => void;
  activeElement: ActiveElement;
  isConfirmationSkipped: boolean;
}

export interface PaymentSectionProps extends BasePaymentMethodProps {
  setDeclarationCompleted: (isCompleted: boolean) => void;
  isLoading: boolean;
  skipPaymentMethod: boolean;
}

export const PaymentSection = (props: PaymentSectionProps) => {
  const { siteConfig } = useSiteConfigContext();
  const { embedParams } = useEmbedParamsContext();
  const { queryParams } = useQueryParams<{ draft_collection_module: string }>();

  const versionIsDraft = getCollectionModuleVersionFromQueryParam(queryParams.draft_collection_module);

  const { auth, environment, organizationId, productModuleKey } = embedParams;
  const {
    setPaymentMethodIsValid,
    productModuleDefinitionQuoteSchema,
    productModule,
    setAccountPermission,
    debitAccountPermission,
    application,
    policyholder,
    prefillValues,
    onNextCompleted,
  } = props;

  const { startedTrack } = useMixpanelTrack();

  useEffect(() => {
    startedTrack({
      stepName: MixpanelStepNames.Payment,
    });
  }, []);

  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType | undefined>();

  const summaryConfig = siteConfig?.payment.wording.summary;
  const summaryData = { ...props.application?.module, policyholder: props.policyholder };
  const isLegacySummary = summaryConfig?.length && summaryConfig?.length <= 6 && _.isObject(summaryConfig[0]);
  const isHandleBarModuleDataSummary = !!(
    summaryConfig?.length &&
    summaryConfig?.length > 6 &&
    _.isObject(summaryConfig[0])
  );

  const displayPaymentDeclaration = siteConfig?.payment.displayOptionalSections.displayPaymentDeclaration;
  const [declarationCompleted, setDeclarationCompleted] = React.useState(!displayPaymentDeclaration);
  const isConfirmationSkipped = siteConfig?.confirmation.displayOptionalSections.displayConfirmation === false;

  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: DebitOrderPaymentInputs.BankName,
  });

  const form = useForm<Partial<FormData>>({
    mode: 'onChange',
    defaultValues: useMemo(() => ({}), []),
  });

  const {
    result: collectionModules,
    isLoading: isLoadingCollectionModules,
    error: collectionModulesError,
  } = usePromise(async () => {
    // check product module settings and run only if there is > 0 collection modules
    let collectionModules: CollectionModule[] = [];
    if (environment === Environment.Sandbox) {
      if (versionIsDraft) {
        collectionModules = await getProductModuleCollectionModules({
          environment,
          auth,
          organizationId,
          productModuleKey,
          version: CollectionModuleDefinitionVersion.Draft,
        });
      } else {
        collectionModules = await getProductModuleCollectionModules({
          environment,
          auth,
          organizationId,
          productModuleKey,
          version: CollectionModuleDefinitionVersion.Live,
        });
      }
    } else {
      collectionModules = await getProductModuleCollectionModules({
        environment,
        auth,
        organizationId,
        productModuleKey,
        version: CollectionModuleDefinitionVersion.Live,
      });
    }

    const paymentMethodOptions = getNewPaymentMethodOptions({
      collectionModules: collectionModules || [],
      paymentMethodTypes,
    });

    const paymentMethodType =
      props.prefillValues?.payment?.payment_method_type ||
      (paymentMethodOptions[0]?.value as PaymentMethodType | undefined);

    form.setValue('paymentMethodTypes', paymentMethodType);
    setPaymentMethodType(paymentMethodType);

    return collectionModules;
  }, []);

  useEffect(() => {
    declarationCompleted &&
      setFormFocusOrBlur({
        activeElement: {
          elementId: DebitOrderPaymentInputs.BankName,
        },
        scrollToId: DebitOrderPaymentInputs.BankName,
        isFirstElement: false,
        disableScrollToElement: true,
      });
  }, [declarationCompleted]);

  const paymentMethodTypes = productModule?.productModuleDefinition?.settings.billing.paymentMethodTypes || [];

  useEffect(() => {
    setPaymentMethodType(form.watch('paymentMethodTypes') as any);
  }, [form.watch('paymentMethodTypes')]);

  const PaymentMethodComponent = getPaymentTypeComponent({
    paymentMethodType,
  });

  const paymentMethodOptions = getNewPaymentMethodOptions({
    collectionModules: collectionModules || [],
    paymentMethodTypes,
  });

  return (
    <div style={{ paddingTop: 20, marginTop: -272 }}>
      <PremiumDisplay siteConfig={siteConfig}>
        <p>Ongoing premium</p>
        <div>
          {getPremiumDisplay({
            premiumValue: application?.monthlyPremium,
            billingFrequency: application?.billingFrequency,
            currency: props.productModule?.productModuleDefinition?.settings.billing.currency,
          })}
        </div>
      </PremiumDisplay>
      {isLegacySummary && (
        <LegacyPaymentSummary
          application={props.application}
          policyholder={props.policyholder}
          productModuleDefinition={props.productModule?.productModuleDefinition}
          isLoadingCollectionModules={isLoadingCollectionModules}
          collectionModulesError={collectionModulesError}
        />
      )}
      {!isLegacySummary && (
        <CombinedPaymentSummary
          application={props.application}
          policyholder={props.policyholder}
          isLoadingCollectionModules={isLoadingCollectionModules}
          collectionModulesError={collectionModulesError}
          isHandleBarModuleDataSummary={isHandleBarModuleDataSummary}
          summaryData={summaryData}
          summaryConfig={summaryConfig as string[]}
        />
      )}
      {displayPaymentDeclaration && (
        <PaymentDeclarationSection
          policyholder={policyholder}
          setDebitAccountPermission={(completed) => {
            setDeclarationCompleted(completed);
            props.setDeclarationCompleted(completed);
            setActiveElement({ elementId: DebitOrderPaymentInputs.BankName });
          }}
        />
      )}
      {declarationCompleted && !props.skipPaymentMethod && !isLoadingCollectionModules && (
        <>
          <FormWrapperStyle>
            <StyledTitle>Payment details:</StyledTitle>
            {paymentMethodOptions.length > 1 && (
              <Row>
                <Col sm={12}>
                  <SelectField
                    clearable={true}
                    isTouched={true}
                    disableScrollToElement={false}
                    hideDivider={true}
                    options={paymentMethodOptions}
                    validators={[
                      {
                        validation: {
                          type: ValidationTypes.REQUIRED,
                        },
                      },
                    ]}
                    name={'paymentMethodTypes'}
                    label='Type'
                    form={form}
                    defaultValue={paymentMethodType}
                    placeholder={'Payment method type'}
                    disableTitle={true}
                    disableActiveElement={true}
                    disableNextButton={true}
                    displayProperties={
                      {
                        activeElement,
                        setActiveElement,
                        nextComponentName: '',
                      } as InputFieldDisplayProperties
                    }
                  />
                </Col>
              </Row>
            )}
          </FormWrapperStyle>
          {paymentMethodType && (
            <PaymentMethodComponent
              isConfirmationSkipped={isConfirmationSkipped}
              prefillValues={prefillValues}
              setAccountPermission={setAccountPermission}
              debitAccountPermission={debitAccountPermission}
              application={application}
              policyholder={policyholder}
              productModule={productModule}
              productModuleDefinitionQuoteSchema={productModuleDefinitionQuoteSchema}
              setPaymentMethodIsValid={(isValid: any) => setPaymentMethodIsValid(isValid)}
              setActiveElement={(activeElement: ActiveElement) => setActiveElement(activeElement)}
              activeElement={activeElement}
              collectionModuleKey={paymentMethodType as string} //do not leave like this
              onNextCompleted={onNextCompleted}
            />
          )}
        </>
      )}
      {declarationCompleted && props.skipPaymentMethod && (
        <SkippedPaymentMethod
          policyholder={policyholder}
          application={application}
          isConfirmationSkipped={isConfirmationSkipped}
        />
      )}
      <br />
    </div>
  );
};

const getPaymentTypeComponent = (params: { paymentMethodType?: PaymentMethodType | string }) => {
  const { paymentMethodType } = params;

  const PaymentMethodComponent: { [key in PaymentMethodType]?: (params: PaymentMethodProps) => JSX.Element } = {
    [PaymentMethodType.DebitOrder]: DebitOrderPaymentMethod,
    [PaymentMethodType.Card]: CreditCardPaymentMethod,
    [PaymentMethodType.External]: ExternalPaymentMethod,
    [PaymentMethodType.Eft]: DebitOrderPaymentMethod,
  };

  // Check if the paymentMethodType is a key in PaymentMethodComponent
  // If not, default to CreateCollectionModulePaymentMethod
  return (paymentMethodType || '') in PaymentMethodComponent
    ? PaymentMethodComponent[paymentMethodType as PaymentMethodType] || CreateCollectionModulePaymentMethod
    : CreateCollectionModulePaymentMethod;
};

export const PremiumDisplay = styled.div<{ siteConfig: ProductModuleDefinitionEmbeddedConfig | null }>`
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  p {
    font-size: 16px;
    display: flex;
    justify-content: center;
    font-weight: 400;
  }

  div {
    font-size: 34px;
    font-weight: 400;
  }

  span {
    font-size: ${globalStyles.fontSize.body};
    color: ${({ siteConfig }) => getColor({ siteConfig, color: 'highlight' })};
    margin-top: ${globalStyles.spacing.xs};
  }
`;
